import React, { Component } from "react";

export default class ServiceCard extends Component {
  render() {
    return (
      <div class="card1" href="/">
        <h3>{this.props.title}</h3>
        <p class="small">{this.props.description}</p>
      </div>
    );
  }
}
