import React, { Component } from "react";
import comoEspejo from "../assets/promotions/comoEspejo.jpg";
import dosporunoLimpieza from "../assets/promotions/dosporunoLimpieza.jpg";
import embelleceSonrisa from "../assets/promotions/embelleceSonrisa.jpg";
import esUnaTerapia from "../assets/promotions/esUnaTerapia.jpg";
import ortodonciaNiños from "../assets/promotions/ortodonciaNiños.jpg";
import sonrisaEsUnaCurva from "../assets/promotions/sonrisaEsUnaCurva.jpg";
import mainlogo200 from "../assets/logos/mainlogo_dzxf2n_c_scale,w_200.jpg";
import mainlogo765 from "../assets/logos/mainlogo_dzxf2n_c_scale,w_765.jpg";
import mainlogo1184 from "../assets/logos/mainlogo_dzxf2n_c_scale,w_1184.jpg";
import mainlogo1280 from "../assets/logos/mainlogo_dzxf2n_c_scale,w_1280.jpg";
import historyIcon from "../assets/icons/information.svg";
import mapIcon from "../assets/icons/dentist.svg";
import facebookIcon from "../assets/icons/facebook.svg";
import messengerIcon from "../assets/icons/messenger.svg";
import phoneCall from "../assets/icons/phone-receiver.svg";
import serivciosIcon from "../assets/icons/servicios.svg";
import ServiceCard from "./ServiceCard";
export default class Page extends Component {
  render() {
    return (
      <div className="site">
        <header className="masthead">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src={comoEspejo} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
                <img src={dosporunoLimpieza} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
                <img src={embelleceSonrisa} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
                <img src={esUnaTerapia} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
                <img src={ortodonciaNiños} class="d-block w-100" alt="..." />
              </div>
              <div class="carousel-item">
                <img src={sonrisaEsUnaCurva} class="d-block w-100" alt="..." />
              </div>
            </div>
          </div>
        </header>
        <h1 className="page-title">
          {/*<span style={{ color: "green" }}>Endo</span>
          <span style={{ color: "purple" }}>dental</span>*/}
          <picture>
            <img
              sizes="(max-width: 1280px) 100vw, 1280px"
              srcset={`
              ${mainlogo200} ,
              ${mainlogo765} ,
              ${mainlogo1184} ,
              ${mainlogo1280} `}
              src="mainlogo_dzxf2n_c_scale,w_1280.jpg"
              alt="Logo"
              style={{ maxWidth: "100%" }}
            />
          </picture>
        </h1>
        <main className="main-content" style={{ padding: "1rem" }}>
          <section id="Servicios">
            <div className="servicetitle text-center">
              <img src={serivciosIcon} alt="" style={{ maxWidth: "4rem" }} />
              <h2>Nuestros servicios</h2>
            </div>

            <div className="descripcion">
              <p>
                En <span sytle={{ color: "purple" }}>endo</span>
                <span style={{ color: "grenyellow" }}>dental</span> cuidamos la
                salud de tu boca por eso te ofrecemos la mejor atención asi como
                profesionales capacitados en todos nuestros servicios
              </p>
            </div>
            <div className="servicio1">
              <ServiceCard
                title="Endodoncia"
                description="Consiste en la extirpación de la pulpa dental y el posterior relleno y sellado de la cavidad pulpar con un material inerte."
              ></ServiceCard>
            </div>
            <div className="servicio2">
              <ServiceCard
                title="Cirugias"
                description="Comunícate con nosotros para asesorarte sobre cirugías bucales."
              ></ServiceCard>
            </div>
            <div className="servicio3">
              <ServiceCard
                title="Ortodoncia"
                description=" Su ejercicio es el arte de prevenir, diagnosticar y corregir sus posibles alteraciones y mantenerlas dentro de un estado óptimo de salud y armonía mediante el uso y control de diferentes tipos de fuerzas."
              ></ServiceCard>
            </div>
            <div className="servicio4">
              <ServiceCard
                title="Implantes"
                description="Implantes diseñados a la medida para sustituir la raíz faltante y mantener el diente artificial en su sitio."
              ></ServiceCard>
            </div>
            <div className="servicio5">
              <ServiceCard
                title="Periodoncia"
                description="El tratamiento de la peridontitis consiste en eliminar el sarro que se acumula alrededor de los dientes, principalmente el que se forma por debajo d ellas encías. El objetivo del tratamiento es limpiar exhaustivamente y prevenir el daño de las piezas afectadas por la enfermedad periodontal."
              ></ServiceCard>
            </div>
            <div className="servicio6">
              <ServiceCard
                title="Protesis fijas y removibles"
                description="Asesorate sobre lo más conveniente para ti. Llamanos al número 2233-6327 o al 5838-2554 "
              ></ServiceCard>
            </div>
            <div className="servicio7">
              <ServiceCard
                title="Puentes fijos"
                description="Un puente dental fijo o prótesis parcial fija es una buena alternativa para los pacientes que han perdido uno o varios dientes naturales.."
              ></ServiceCard>
            </div>
            <div className="servicio8">
              <ServiceCard
                title="Blanqueamiento clinico y casero etc"
                description="El Blanqueamiento Dental es uno de los procedimientos cosméticos más comunes y efectuados con más regularidad por los dentistas, logrando reducir varios tonos del color original, resultando en una dentadura más blanca y brillante."
              ></ServiceCard>
            </div>
          </section>

          <section id="SobreNosotros">
            <div className="container-fluid">
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 text-center">
                  <img src={historyIcon} alt="" style={{ maxWidth: "4rem" }} />
                  <h2>Conocenos</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h4 className="text-center">
                    <strong>Misión</strong>
                  </h4>
                  <p>
                    Nuestro compromiso es tu salud bucal, Brindando servicios
                    odontológicos multidisciplinarios que contribuyan a resolver
                    los problemas de salud Bucal en cada uno de nuestros
                    pacientes , trabajando con excelencia en el servicio,
                    eficiencia en cada uno de los tratamientos y cuidado
                    personalizado en la atención de cada paciente.  
                  </p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <h4 className="text-center">
                    <strong>Visión</strong>
                  </h4>
                  <p>
                    {" "}
                    Ser la clinica odontológica referente para el cuidado de tu
                    salud bucal, trabajando con profesionalismo , excelencia y
                    así satisfacer las necesidades de nuestros pacientes.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="container-fluid" id="Ubicaciones">
            <div className="row justify-content-center align-items-center ">
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 text-center">
                <img src={mapIcon} alt="" style={{ maxWidth: "4rem" }} />
                <h2>Nuestras Clínicas</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ul>
                  <li>CC Ecocentro los Álamos, local LC 02</li>
                </ul>
              </div>
              <div className="col">
                <div className="largemap">
                  <iframe
                    title="mapa"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3862.32771691577!2d-90.53442501942503!3d14.523237748811514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a735e725fc91%3A0xd7e24c91a75d0c8f!2sEcoCentro%20Los%20%C3%81lamos!5e0!3m2!1ses-419!2sgt!4v1573844992874!5m2!1ses-419!2sgt"
                    width="600"
                    height="450"
                    frameborder="0"
                    style={{ border: "0" }}
                    allowfullscreen=""
                  ></iframe>
                </div>
              </div>
              <div className="col-11">
                <div className="smallmap">
                  <iframe
                    title="mapa"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3862.32771691577!2d-90.53442501942503!3d14.523237748811514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a735e725fc91%3A0xd7e24c91a75d0c8f!2sEcoCentro%20Los%20%C3%81lamos!5e0!3m2!1ses-419!2sgt!4v1573844992874!5m2!1ses-419!2sgt"
                    width="300"
                    height="400"
                    frameborder="0"
                    style={{ border: "0" }}
                    allowfullscreen=""
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </main>

        <aside className="sidebar">
          <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo03"
              aria-controls="navbarTogglerDemo03"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul class="navbar-nav mt-2 mt-lg-0">
                <li class="nav-item active">
                  <a class="nav-link" href="#SobreNosotros">
                    Sobre Nosotros
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#Servicios">
                    Servicios
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#Ubicaciones">
                    Ubicaciones
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#Contacto">
                    Contacto
                  </a>
                </li>
              </ul>
            </div>
            <a class="btn btn-outline-warning" href="/">
              ¡Agenda tu cita ahora!
            </a>
          </nav>
          {/*<ul>
            <li>Endodental</li>
            <li>Servicios</li>
            <li>Ubicaiones</li>
            <li>Sobre nosotros</li>
            <li>
              <div className="btn btn-warning">Agenda tu cita</div>
            </li>
          </ul>*/}
        </aside>
        <footer className="footer" id="Contacto">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-8">
                <div className="badge badge-info">Información de Contacto</div>
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <strong>Comunícate con nuestro equipo</strong>
                  </li>
                  <li>
                    {" "}
                    <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F191824184752003%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink">
                      {" "}
                      <img src={messengerIcon} alt="" aria-label="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/Endodental-cl%C3%ADnica-dental-191824184752003/">
                      {" "}
                      <img src={facebookIcon} alt="" aria-label="messenger" />
                    </a>
                  </li>

                  <li>
                    <img src={phoneCall} alt="" aria-label="phoneCall" />
                    <label htmlFor="phoneCall">2233-6327 y 5838-2554</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>

        <codelines className="CodelinesCopyrigth">
          <div className="container-fluid" style={{ height: "100%" }}>
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "start",
                height: "100%"
              }}
            >
              <div className="col-4">@Codelines</div>
            </div>
          </div>
        </codelines>
      </div>
    );
  }
}
