import React from "react";
import Page from "./components/Page";

function App() {
  return (
    <div className="App">
      <div className="cotainer">
        <Page></Page>
      </div>
    </div>
  );
}

export default App;
